import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import PrimaryTextButton from "../shared/primary-text-button";
import SecondaryTextButton from "../shared/secondary-text-button";
import { useFeatureFlagVariantKey } from "posthog-js/react";

const styles = {
  h1Container: {
    Wasatch: "max-w-lg",
    Mountainland: "max-w-xl",
  },
};

const buttons = [
  {
    title: "View Covers",
    to: "/window-well-covers/",
  },
  {
    title: "Build a Quote",
    to: "/quote-builder/",
  },
];

const HomeBackgroundSection = ({ homePageData }) => {
  const variant = useFeatureFlagVariantKey("swap-buttons-on-home-page-hero");

  const buttonsToRender =
    variant === "build-a-quote-first" ? [buttons[1], buttons[0]] : buttons;

  return (
    <section className={classNames("relative min-h-[60vh] bg-grey-darkest")}>
      <div
        className={classNames(
          "absolute inset-0 h-full gap-4 transition-opacity duration-500 ease-in"
        )}
      >
        <GatsbyImage
          className={classNames("w-full h-full")}
          imgClassName="object-cover object-bottom"
          image={
            homePageData?.pages?.homePageSettings?.backgroundImage?.gatsbyImage
          }
          alt={
            homePageData?.pages?.homePageSettings?.backgroundImage?.altText ||
            "Safe and secure window well covers"
          }
        />
      </div>
      <div
        className="absolute inset-0"
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
        }}
      />

      <div
        className={classNames(
          "relative z-10 max-w-4xl flex flex-col items-start justify-center h-full px-4 min-h-[60vh] mx-auto",
          "lg:px-24 lg:py-32"
        )}
      >
        <div
          className={classNames(
            "flex flex-col justify-center items-start gap-6 text-white h-full pt-12 pb-24 lg:pb-32",
            styles.h1Container[process.env.COMPANY_NAME_SHORTER]
          )}
        >
          <h1
            className="text-4xl lg:text-[72px]"
            style={{
              fontWeight: 700,
            }}
          >
            {homePageData?.pages?.h1Title ||
              `${process.env.STATE_NAME}'s BEST Custom Window Well Covers`}
          </h1>
          <div
            className="font-light max-w-md"
            dangerouslySetInnerHTML={{
              __html: homePageData?.pages?.homePageSettings?.openingParagraph,
            }}
          />
          <div className="flex gap-6 text-white items-center">
            <PrimaryTextButton to={buttonsToRender[0].to}>
              {buttonsToRender[0].title}
            </PrimaryTextButton>
            <SecondaryTextButton to={buttonsToRender[1].to}>
              {buttonsToRender[1].title}
            </SecondaryTextButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBackgroundSection;
